<template>
  <div class="expert-profile-heading">
    <div class="d-flex align-items-center mt-4 mb-4">
      <h2 class="primary-text">YOUR PROFILE</h2>
      <button
        @click="openExpertProfileDrawer"
        class="btn btn-sm btn-primary preview-btn"
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-trigger="hover"
      >
        PREVIEW
      </button>
      <ExpertProfileDrawer ref="expertProfileDrawerRef" :expert-id="expertId" />
    </div>
    <p class="primary-text fw-bold">
      Create your smart capital profile. Add some skills, show where your
      network is<br />
      strongest, define your investment preferences, availabilities and more...
      <a target="_blank" href="https://7founders.com/profile-e">
        example.
      </a>
    </p>
  </div>
</template>

<script>
import ExpertProfileDrawer from "./ExpertProfileDrawer";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ExpertProfileHeading",
  components: {
    ExpertProfileDrawer,
  },
  setup() {
    const store = useStore();
    const expertProfileDrawerRef = ref(null);
    const expertId = computed(() => {
      return store.getters["UserModule/getExpertId"];
    });
    const openExpertProfileDrawer = () => {
      expertProfileDrawerRef.value.showDrawer(expertId.value);
    };
    return {
      expertId,
      expertProfileDrawerRef,
      openExpertProfileDrawer,
    };
  },
};
</script>

<style scoped></style>
