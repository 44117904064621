<template>
  <div>
    <ExpertProfileHeading />
    <ExpertProfileOverview />
    <router-view />
  </div>
</template>

<script>
import ExpertProfileOverview from "../components/ExpertProfileOverview";
import ExpertProfileHeading from "../components/ExpertProfileHeading";
import { useStore } from "vuex";
import { onMounted } from "vue";
export default {
  name: "ExpertProfile",
  components: { ExpertProfileOverview, ExpertProfileHeading },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllProjectStatuses");
      await store.dispatch("ExpertModule/fetchExpertProjectStatuses", false);
      await store.dispatch("ExpertModule/fetchExpertRates", false);
    });
  },
};
</script>

<style scoped></style>
