
import { computed, defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { getExpertInfo, updateExpertProfileImage } from "../../api/expert.api";
import { ref } from "vue";
import { getAuth } from "firebase/auth";
import { CloseBold } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "ExpertProfileOverview",
  components: {
    CloseBold,
  },
  props: {
    expertId: {
      type: Number,
      default: undefined,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const uploadProfileImageLoading = ref(false);
    const expertProfileImageUrlLocal = ref("");
    const expertDayRate = computed(() => {
      const expertRates = store.getters["ExpertModule/getExpertRates"];
      for (const expertRate of expertRates) {
        if (expertRate.expertRates.type === "perDay") {
          return expertRate.rate;
        }
      }
      return 0;
    });

    const expertProfileImageUrl = computed(() => {
      return store.getters["ExpertModule/getExpertProfileImageUrl"];
    });

    const expertLinkedProfileLink = computed(() => {
      return store.getters["ExpertModule/getExpertProfileLink"];
    });

    const linkButtonText = computed(() => {
      if (expertLinkedProfileLink.value.includes("linkedin")) {
        return "Linkedin";
      }
      if (expertLinkedProfileLink.value.includes("xing")) {
        return "Xing";
      }
      return null;
    });

    const expertDayDiscount = computed(() => {
      const expertRates = store.getters["ExpertModule/getExpertRates"];
      for (const expertRate of expertRates) {
        if (expertRate.expertRates.type === "perDay") {
          return expertRate.discountPercentage;
        }
      }
      return 0;
    });

    const expertInfo = ref({
      seniorityLevel: {
        name: "",
      },
      city: "",
      email: "",
    });

    const handleExpertProfileImageUpload = async (event) => {
      let uploadedImage = event.target.files[0];
      if (uploadedImage.type.match("image.*")) {
        uploadProfileImageLoading.value = true;
        let preservedImage;
        try {
          preservedImage = expertProfileImageUrlLocal.value;
          expertProfileImageUrlLocal.value = URL.createObjectURL(uploadedImage);
          const formData = new FormData();
          formData.append("photo", uploadedImage);
          await updateExpertProfileImage(formData);
          await store.dispatch("UserModule/fetchUserAndSave");
        } catch (e) {
          expertProfileImageUrlLocal.value = preservedImage;
          ElNotification({
            title: "Error",
            message: "Please uploading profile image",
            type: "error",
          });
        } finally {
          uploadProfileImageLoading.value = false;
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Please attach a valid image",
          type: "error",
        });
      }
    };

    onMounted(async () => {
      const { data } = await getExpertInfo();
      expertInfo.value = { ...data.data, email: getAuth().currentUser?.email };
    });

    watch(
      () => expertProfileImageUrl.value,
      (value) => {
        expertProfileImageUrlLocal.value = value;
      },
      { immediate: true }
    );

    return {
      expertInfo,
      expertDayRate,
      expertDayDiscount,
      expertLinkedProfileLink,
      expertProfileImageUrl,
      uploadProfileImageLoading,
      expertProfileImageUrlLocal,
      linkButtonText,
      getAuth,
      handleExpertProfileImageUpload,
    };
  },
});
